/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * エラーコード
 */
export enum ErrorCode {
    INVALID_ARGUMENT = 'invalid_argument',
    AUTHENTICATION_ERROR = 'authentication_error',
    INTERNAL_SERVER_ERROR = 'internal_server_error',
    TOKEN_EXPIRED = 'token_expired',
    SOLD_OUT = 'sold_out',
    PROJECT_EXPIRED = 'project_expired',
    INCORRECT_DATA = 'incorrect_data',
    PAYMENT_TIMEOUT = 'payment_timeout',
    SESSION_TIMEOUT = 'session_timeout',
    CSRF_ERROR = 'csrf_error',
    PAYMENT_SERVICE_INVALID_REQUEST = 'payment_service_invalid_request',
    PAYMENT_SERVICE_TRANSACTION_ERROR = 'payment_service_transaction_error',
    PAYMENT_SERVICE_AUTH_ERROR = 'payment_service_auth_error',
    PAYMENT_SERVICE_TDS_AUTH_ERROR = 'payment_service_tds_auth_error',
    PAYMENT_SERVICE_SERVER_ERROR = 'payment_service_server_error',
    FRAUD_DETECTED_USER = 'fraud_detected_user',
    TDS_SYSTEM_ERROR = 'tds_system_error',
}
