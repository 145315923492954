<script setup lang="ts">
import PaymentErrorLayout from './PaymentErrorLayout.vue';
import {
  ErrorCode,
  type TranDestinationResponse,
  TranService,
} from '@/generated/payment-api';
import { unexpectedErrorMessage, errorMessageMap } from '@/consts';

import { inject, onMounted, ref } from 'vue';
import { MButton } from '@ca-crowdfunding/makuake-ui-n';
import { type errorStore, errorStoreKey } from '@/store/error';
import { createMakuakeWebOrderURL } from '@/utils';
import router from '@/router';
import {
  TIMEOUT_ERROR,
  TIMEOUT_ERROR_BY_EXEC,
} from '@/generated/payment-api/core/request';
import { useRoute } from 'vue-router';

const store = inject(errorStoreKey) as errorStore;
const errorMessages = ref<string[]>([]);
const errorCode = ref<string>('');
const destination = ref({
  projectCode: '',
  projectReturnID: 0,
  orderID: '',
});

onMounted(() => {
  const route = useRoute();
  const queryErrorCode = route.query.error_code;
  if (queryErrorCode && typeof queryErrorCode === 'string') {
    // コールバックで遷移してきた場合にクエリパラメータにエラーコードが付加される
    errorCode.value = queryErrorCode;
    errorMessages.value = [getErrorMessageFromErrorCode(queryErrorCode)];
  } else {
    errorCode.value = store.code;
    errorMessages.value = store.messages;
  }
  if (store.code === ErrorCode.SESSION_TIMEOUT) {
    return;
  }
  TranService.tranDestination()
    .then((response: TranDestinationResponse) => {
      destination.value = {
        projectCode: response.project_code,
        projectReturnID: response.project_return_id,
        orderID: response.order_id,
      };
    })
    // catchを省くと、Axiosの内部エラーが出てしまう。ここは何もハンドリングしないのが正しいので、catchして何もしない。
    .catch(() => {});
});

const handleProjectButton = () => {
  const url = [
    import.meta.env.VITE_MAKUAKE_URL,
    'project',
    destination.value.projectCode,
  ];
  store.clear();
  window.location.assign(url.join('/'));
};

const handleFavoriteButton = () => {
  const url = [import.meta.env.VITE_MAKUAKE_URL, 'my/project/favorite'];
  store.clear();
  window.location.assign(url.join('/'));
};

const handleOrderButton = () => {
  const url = createMakuakeWebOrderURL(
    destination.value.projectCode,
    destination.value.projectReturnID,
    destination.value.orderID,
  );
  store.clear();
  window.location.assign(url);
};

const handleReenterButton = () => {
  store.clear();
  router.push('/input/credit');
};

const handleTopButton = () => {
  store.clear();
  window.location.assign(import.meta.env.VITE_MAKUAKE_URL);
};

const handleSelectAnotherPaymentTypeButton = () => {
  store.clear();
  router.push('/select');
};

const getErrorCodeFromString = (
  errorCodeString: string,
): ErrorCode | undefined => {
  if (Object.values(ErrorCode).includes(errorCodeString as ErrorCode)) {
    return errorCodeString as ErrorCode;
  }
  // 想定していないエラーの場合
  return undefined;
};

// NOTE: コールバックでこの画面に遷移時のエラーメッセージを取得する
// コールバックで遷移時以外のエラーについては現状考慮されてないので注意。src/consts/errorMessageを参照
// FIXME: コールバック時のエラーメッセージとそれ以外で二重管理になっている
const getErrorMessageFromErrorCode = (errorCodeString: string): string => {
  const errorCode = getErrorCodeFromString(errorCodeString);
  if (errorCode) {
    const message = errorMessageMap.get(errorCode) ?? unexpectedErrorMessage;
    return message;
  }
  return unexpectedErrorMessage;
};
</script>
<template>
  <PaymentErrorLayout :errorMessages="errorMessages">
    <template v-slot:contents>
      <p>エラーが発生しました</p>
    </template>
    <template v-slot:buttons>
      <div class="flex-wrap">
        <MButton
          v-if="
            errorCode === ErrorCode.SOLD_OUT ||
            errorCode === TIMEOUT_ERROR ||
            errorCode === ErrorCode.PAYMENT_SERVICE_SERVER_ERROR
          "
          @click="handleProjectButton"
          size="x-large"
          rounded="none"
          class="button button-yellow flex-content"
        >
          プロジェクトページへ戻る
        </MButton>
        <MButton
          v-else-if="errorCode === TIMEOUT_ERROR_BY_EXEC"
          @click="handleFavoriteButton"
          size="x-large"
          rounded="none"
          class="button button-yellow"
        >
          応援購入履歴画面に戻る
        </MButton>
        <MButton
          v-else-if="
            errorCode === ErrorCode.PAYMENT_TIMEOUT ||
            errorCode === ErrorCode.TDS_SYSTEM_ERROR ||
            errorCode === ErrorCode.PAYMENT_SERVICE_TDS_AUTH_ERROR ||
            errorCode === ErrorCode.PAYMENT_SERVICE_INVALID_REQUEST ||
            errorCode === ErrorCode.PAYMENT_SERVICE_TRANSACTION_ERROR
          "
          @click="handleOrderButton"
          size="x-large"
          rounded="none"
          class="button button-yellow"
        >
          注文画面に戻る
        </MButton>
        <MButton
          v-else-if="errorCode === ErrorCode.PAYMENT_SERVICE_AUTH_ERROR"
          @click="handleReenterButton"
          size="x-large"
          rounded="none"
          class="button button-yellow"
        >
          カード情報を再入力する
        </MButton>
        <MButton
          v-else
          @click="handleTopButton"
          size="x-large"
          rounded="none"
          class="button button-yellow"
        >
          ホームへ戻る
        </MButton>
        <MButton
          v-if="errorCode === ErrorCode.PAYMENT_SERVICE_AUTH_ERROR"
          @click="handleSelectAnotherPaymentTypeButton"
          color="info"
          size="x-large"
          rounded="none"
          class="button"
        >
          別の決済手段を選ぶ
        </MButton>
      </div>
    </template>
  </PaymentErrorLayout>
</template>

<style scoped>
.button {
  border: 0;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
  flex: 1;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
  padding: 20px;
  text-align: center;
  width: 335px;
  margin-bottom: 20px;
}

.button-yellow {
  background: #f7c600;
}

.flex-wrap {
  display: flex;
  flex-flow: column;
  align-items: center;
}
</style>
